import React from 'react';
import { StyledPartners } from "./styles";
import { Container, Col, Row } from "styled-bootstrap-grid"

const Partners = ({ children }) => {
  return (
    <Container>
      <Row>
        <Col col={12}>
          <StyledPartners>
            {children}
          </StyledPartners>
        </Col>
      </Row>
    </Container>
  )
}

export default Partners;