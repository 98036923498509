import React, { useEffect, useState } from "react"
import { StyledFeaturedContent, MobileWrapper } from "./styles";
import { Container, Col, Row } from "styled-bootstrap-grid";
import { HideOnDesktop } from "../../../../components/styles"
import { useInView } from 'react-intersection-observer';

const FeaturedContents = ({ children, mobileCarouselItems }) => {
  const { ref, inView } = useInView({
    threshold: 0.75,
  });
  const [ isTriggered, trigger ] = useState(false);

  useEffect(() => {
    if (inView) {
      trigger(true);
    }
  }, [ inView ])
    return (
    <div style={{ backgroundColor: '#f9f9f9' }}>
      <Container>
        <Row>
          <Col>
            <StyledFeaturedContent ref={ref} inView={isTriggered}>
              {children}
              <HideOnDesktop>
                <MobileWrapper>
                  {mobileCarouselItems?.filter((item) => item?.props)?.map((item, i) => (
                    <div key={i}>
                      {item}
                    </div>
                  ))}
                </MobileWrapper>
              </HideOnDesktop>
            </StyledFeaturedContent>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FeaturedContents;
