import React from 'react';
import { StyledFeaturedClients } from './styles';
import { Container, Col, Row } from "styled-bootstrap-grid"
import ClientGrid from "../../../../components/ClientsGrid"

const FeaturedClients = ({ children }) => {
  return(
    <div>
      <Container>
        <Row>
          <Col col={12}>
            <StyledFeaturedClients>
              {children}
            </StyledFeaturedClients>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col col={12}>
            <ClientGrid maxLength={5} />
          </Col>
        </Row>
      </Container>
    </div>
  )
};

export default FeaturedClients;