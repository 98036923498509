import React, { useState, useRef } from 'react';
import Slider from "react-slick";
import {
  BorderLeftCol,
  StyledTestimonials,
  LogoRow,
  LogoWrap,
} from "./styles";
import { Container, Col, Row } from "styled-bootstrap-grid"

const Testimonials = (props) => {
  const { carouselContents, children, navContents } = props;

  const slider = useRef();
  
  const [slider1, setSlider1] = useState(null);
  const [slider2] = useState(null);
  const [ slideIndex, setSlideIndex ] = useState(0);
  const [ slideCount, setCount ] = useState(0);

  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: () => setCount(slideCount + 1),
    beforeChange: (current, next) => setSlideIndex(next)
  };

  const settings2 = {
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    afterChange: () => setCount(slideCount + 1),
    beforeChange: (current, next) => setSlideIndex(next)
  };

  return (
    <StyledTestimonials htmlStyles={props?.style}>
      <Container>
        <Row>
          <Col col={12} md={6}>
            {children}
          </Col>
          <BorderLeftCol col={12} md={6}>
            <LogoRow>
            <Slider className='testimonials-slider' asNavFor={slider2} ref={(slider) => setSlider1(slider)} {...settings2}>
              {navContents?.filter((item) => item.props)?.map((item, i, j = 0) => (
                <LogoWrap
                  active={slideIndex === i}
                  key={i}
                  onClick={() => slider.current.slickGoTo(i)}
                >
                  
                  {item}
                </LogoWrap>
              ))}
            </Slider>
            </LogoRow>
            <Slider asNavFor={slider1} ref={c => (slider.current = c)} {...settings}>
              {carouselContents?.filter((item) => item?.props)?.map((item, i) => (
                <div key={i}>
                  {item}
                </div>
              ))}
            </Slider>
          </BorderLeftCol>
        </Row>
      </Container>
    </StyledTestimonials>
  )
}

export default Testimonials;
