import React from 'react';
import Hero from "../templates/Homepage/components/Hero";
import FeaturedContents from "../templates/Homepage/components/FeaturedContents";
import FeaturedVerticals from "../templates/Homepage/components/FeaturedVerticals";
import FeaturedClients from "../templates/Homepage/components/FeaturedClients";
import Partners from "../templates/Homepage/components/Partners";
import Contact from "../components/Contact"
import FeaturedPosts from "../templates/Homepage/components/FeaturedPosts";
import Testimonials from "../templates/Homepage/components/Testimonials";
import { SectionPadding } from "../templates/Homepage/sharedStyles"
import { domToReact } from 'html-react-parser';

const replaceElements = (node) => {
  if (node?.attribs?.class === "hero") {
    const mobileCarouselItems = node?.children?.find(
      ({ attribs }) => attribs?.class === "container")?.children?.find(
        ({ attribs }) => attribs?.class === "hero__features")?.children;
    return (
      <Hero
        mobileCarouselItems={domToReact(mobileCarouselItems)}
      >
        {domToReact(node.children)}
      </Hero>
    );
  }
  if (node?.attribs?.class === "section section--featured-contents") {
    const mobileCarouselItems = node?.children?.find(
      ({ attribs }) => attribs?.class === "container container--md")?.children?.find(
        ({ attribs }) => attribs?.class === "featured-contents__list").children;
    return (
      <FeaturedContents mobileCarouselItems={domToReact(mobileCarouselItems)}>
        {domToReact(node.children)}
      </FeaturedContents>
    )
  }
  if (node?.attribs?.class === "section section--featured-verticals") {
    const container = node?.children?.find(({ attribs }) => attribs?.class === "container");
    const title = container?.children?.find(({ attribs }) => attribs?.class === "section__title");
    const description = container?.children?.find(({ attribs }) => attribs?.class === "section__description");
    const verticals = container?.children?.find(({ attribs }) => attribs?.class === "featured-verticals-grid__list");
    return (
      <FeaturedVerticals
        title={domToReact(title.children)}
        description={domToReact(description?.children ?? '')}
        verticals={verticals?.children}
      >
        {domToReact(node.children)}
      </FeaturedVerticals>
    )
  }
  if (node?.attribs?.class.includes("section section--featured-clients-grid")) {
    return (
      <FeaturedClients>
        {domToReact(node.children)}
      </FeaturedClients>
    )
  }
  if (node?.attribs?.class === "section section--featured-icons") {
    return (
      <Partners>
        {domToReact(node.children)}
      </Partners>
    )
  }
  if (node?.attribs?.class === "section section--testimonials-slider") {
    const carouselContents =
      node?.children?.find(
        ({ attribs }) => attribs?.class === "container container--md")?.children?.find(
          ({ attribs }) => attribs?.class === "section__right")?.children?.find(
            ({ attribs }) => attribs?.class === "testimonials-slider__list")?.children;
    const navContents =
      node?.children?.find(
        ({ attribs }) => attribs?.class === "container container--md")?.children?.find(
          ({ attribs }) => attribs?.class === "section__right")?.children?.find(
            ({ attribs }) => attribs?.class === "testimonials-slider__nav")?.children;

    return (
      <>
        <Testimonials
          {...node.attribs}
          carouselContents={domToReact(carouselContents)}
          navContents={domToReact(navContents)}
        >
          {domToReact(node.children)}
        </Testimonials>
      </>
    )
  }
  if (node?.attribs?.class === "section section--featured-posts-grid") {
    const title = node?.children?.find(({ attribs }) => attribs?.class === "container")?.children?.find(
      ({ attribs }) => attribs?.class === "section__title");
    const description = node?.children?.find(({ attribs }) => attribs?.class === "container")?.children?.find(
      ({ attribs }) => attribs?.class === "section__description");
    return (
      <>
        <FeaturedPosts
          {...node.attribs}
          title={domToReact(title.children)}
          description={domToReact(description.children)}
        >
          {domToReact(node.children)}
        </FeaturedPosts>
      </>
    )
  }
  if (node?.attribs?.class === "section section--intro-module") {
    return (
      <>
        <SectionPadding />
        <SectionPadding />
        <Contact />
      </>
    )
  }
  else {
    return node;
  }
};

export default replaceElements;
