import React, { useEffect, useState } from 'react';
import Seo from "../../components/seo"
import parse from "html-react-parser"
import Layout from "../../components/layout";
import homepage from "../../HtmlContentParsers/homepage";

const Homepage = ({ data: { wpPage, allWpMediaItem } }) => {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);
  return (
    <Layout key={isClient}>
      <Seo title={wpPage?.title} />
        {(typeof wpPage?.content === 'string' || wpPage?.content instanceof String) ? parse(
          wpPage?.content,
          {
            replace: (node) => homepage(node, allWpMediaItem)
          }
        ) : ''}
    </Layout>
  );
};

export default Homepage;
