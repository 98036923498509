import React from 'react';
import { StyledFeaturedPosts } from "./styles";
import { SectionPadding } from "../../sharedStyles"
import { Container, Col, Row } from "styled-bootstrap-grid"
import { graphql, useStaticQuery } from "gatsby"
import Card from "../../../../components/Card"

const FeaturedPosts = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allWpPost(filter: {ACFPostOptions: {isFeaturedPost: {eq: true}}}) {
        nodes {
          featuredImage {
            node {
              localFile {
                publicURL
              }
              id
            }
          }
          excerpt
          title
          slug
          date(formatString: "YYYY-MM-DD")
          databaseId
        }
      }
    }
  `)
  const { allWpPost: { nodes } } = data;
  const { style = "", title, description } = props;
  return (
    <StyledFeaturedPosts htmlStyle={style}>
      <div className="container">
        <Container>
          <Row className='justify-content-center'>
            <Col col={12}>
              <h2 className='section__title show'>{title}</h2>
              <div className='section__description show'>{description}</div>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row className='justify-content-center'>
            {nodes?.sort((a, b) => b.databaseId - a.databaseId).map(({ featuredImage, title, slug, excerpt, date }, i) => (
              <Col col={12} md={3} key={i}>
                <Card
                  excerpt={excerpt}
                  link={slug}
                  img={featuredImage?.node?.localFile?.publicURL}
                  title={title}
                  date={date}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <SectionPadding />
    </StyledFeaturedPosts>
  )
}

export default FeaturedPosts;
