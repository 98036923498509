import styled from 'styled-components';
import { HomepageStyles } from "../../sharedStyles"
import { media } from "styled-bootstrap-grid"

export const StyledFeaturedContent = styled.section`
  ${HomepageStyles}
  .featured-contents__list {
    display: none;
    ${media.md`
      display: flex;
      flex-flow: row wrap;
      scroll-snap-typ: none;
      scroll-padding: 0;
    `}
    .featured-contents__item {
      text-decoration: none;
      color: rgba(0,0,0,.8);
      text-align: center;
      flex: 1;
      padding: 1rem;
      opacity: 0;
      transform: translateX(-100%);
      transition: 1s;
      &:nth-child(2) {
        transition-delay: 100ms;
      }
      &:nth-child(3) {
        transition-delay: 200ms;
      }
      &:nth-child(4) {
        transition-delay: 300ms;
      }
      ${({ inView }) => inView && `
        opacity: 1;
        transform: translateX(0);
      `}
      ${media.md`
        min-width: 100px;
      `}
      svg {
        fill: rgba(0,0,0,0);
        transition: fill 0.4s;
        width: 90px;
        height: 90px;
      }
      &:hover {
        cursor: pointer;
        svg {
          fill: rgb(255, 198, 0);
        }
      }
      h5 {
        font-weight: 500;
        font-size: 22px;
        line-height: 1.18;
        margin: 26px auto 12px;
      }    
      p {
        font-weight: 300;
        font-size: 16px;
        line-height: 1.5;
      }
    }
  }
`;

export const MobileWrapper = styled.div`
  padding: 32px 0;
  text-align: center;
  svg {
    fill: rgba(0,0,0,0);
    transition: fill 0.4s;
    width: 90px;
    height: 90px;
  }
  .slick-dots li button:before {
    font-size: 16px;
  }
  .slick-dots li.slick-active button:before {
    opacity: 1;
  }
  .featured-contents__item {
    margin: 32px;
    text-decoration: none;
    color: rgba(0,0,0,.8);
    h5 {
      font-weight: 500;
      font-size: 32px;
      line-height: 1.18;
      margin: 26px auto 12px;
    }    
    p {
      font-weight: 300;
      font-size: 18px;
      line-height: 1.5;
    }
  }
`;