import React, { useEffect, useState, useRef } from "react"
import { StyledHero, MobileCarouselWrapper } from './styles';
import { Container, Col, Row } from "styled-bootstrap-grid";
import { HideOnDesktop } from "../../../../components/styles";
import { useInView } from 'react-intersection-observer';
import { StaticQuery, graphql } from "gatsby"
import Slider from "react-slick";

const settings = {
  autoplay: true,
  arrows: false,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Hero = ({
  mobileCarouselItems,
  bgImage,
  children
}) => {
  const [ animate, toggleAnimate ] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.75,
  });


  useEffect(() => {
    if (inView && !animate) {
      toggleAnimate(true);
    }
  }, [ inView, animate ])

  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <>
    <Container fluid>
      <Row>
        <Col col={12} noGutter>
          <StaticQuery
          query={graphql`
          query{
            allWpPage(filter: {id: {eq: "cG9zdDo0MQ=="}}) {
              edges {
                node {
                  hero_module {
                    fieldGroupName
                    heroDescription
                    heroTitle
                    heroImage {
                      mediaItemUrl
                      sourceUrl
                    }
                  }
                }
              }
            }
          }
          `}

          render={data => (
            <StyledHero
            bgImage={data.allWpPage.edges[0].node.hero_module.heroImage.mediaItemUrl}
            className={data.allWpPage.edges[0].node.hero_module.heroImage.mediaItemUrl.includes('.mp4') ? 'video' : 'bg-image'}
            ref={ref}
            inView={animate}
          >
            {data.allWpPage.edges[0].node.hero_module.heroImage.mediaItemUrl.includes('.mp4') ? <video playsInline muted loop ref={videoEl} id="myVideo"><source src={data.allWpPage.edges[0].node.hero_module.heroImage.mediaItemUrl} type="video/mp4"/></video> : <></>}

            {children}
            <HideOnDesktop>
              <MobileCarouselWrapper>
                <Slider {...settings}>
                  {mobileCarouselItems?.filter((item) => item?.props)?.map((item, i) => (
                    <div key={i}>
                      {item}
                    </div>
                  ))}
                </Slider>
              </MobileCarouselWrapper>
            </HideOnDesktop>
          </StyledHero>
          )}
          />
        </Col>
      </Row>
    </Container>
  </>
  );
}

export default Hero;
