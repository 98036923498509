import styled from 'styled-components';
import { HomepageStyles } from "../../sharedStyles";
import { media } from "styled-bootstrap-grid";

export const StyledPartners = styled.section`
  ${HomepageStyles}
  .featured-icons__list {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    justify-content: space-between;
    align-items: space-between;
    width: 100%;
    ${media.md`
     flex-flow: row wrap;
    `}
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 32px);
      height: calc(100% - 32px);
      z-index: -1;
      margin: 16px;
      background-color: rgba(143,143,143, 0.5);
    }
    ${media.md`
      &.featured-icons__list--3 .featured-icons__item {
        max-width: calc((100% / 3) - 0.5px);
        &:last-child {
          max-width: calc((100% / 3) - 0.5px);
        }
      }
    `}
    .featured-icons__item {
      background-color: white;
      margin-top: 1px;
      width: calc(50% - 0.5px);
      padding: 16px;
      min-height: 192px;
      display: flex;
      align-items: center;
      ${media.md`
        padding: 64px;
      `}
      > div, > a {
        max-width: 90% !important;
        display: block;
        margin: auto;
        max-height: 200px;
        img {
          object-fit: scale-down !important;
        }
      }
    }
  }
`;