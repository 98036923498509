import React from "react";
import Homepage from "../templates/Homepage/index";
import { graphql } from "gatsby"

const IndexPage = ({ data }) => <Homepage data={data}/>;

export default IndexPage;

export const query = graphql`
  query {
    wpPage(isFrontPage: {eq: true}) {
      title
      content
    }
    allWpMediaItem {
      edges {
        node {
          sourceUrl
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;
