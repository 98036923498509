import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

export const StyledHero = styled.section`
  position: relative;
  color: white;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  min-height: 700px;
  flex-flow: column-reverse;
  ${({ bgImage }) => bgImage && `
    background-image: url('${bgImage}');
  `}
  ${media.md`

    min-height: 660px;
    flex-flow: column;
    &:after {
      content: '';
      background: linear-gradient(180deg, rgba(0,0,0,0) 16.54%, #000 123.62%);
      position: absolute;
      width: 100%;
      height: 300px;
      left: 0;
      bottom: 0;
    }
  `}
  #myVideo {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
  }
  .container {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1;
    flex: 1;
    padding: 32px 32px 0;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,.1);
    text-align: center;
    display: flex;
    flex-flow: column;
    .hero__title {

      font-size: 50px;
      margin-bottom: 0;
      padding-bottom: 32px;
      text-align: left;
      margin-top: 100px;
      ${media.md`
      margin-top: auto;
        letter-spacing: 3.35px;
        text-align: center;
        padding: 0;
        padding-top: 80px;
        font-size: 72px;
      `}
    }
    .hero__description {
      margin-bottom: auto;
      font-size: 22px;
      font-weight: 400;
      padding: 0;
      line-height: 1.5;
      text-align: left;
      ${media.md`
        letter-spacing: -1.2px;
        line-height: 1.15;
        text-align: center;
        font-size: 30px;
        margin-top: 3rem;
      `}
    }
    .hero__features {
      display: none;
      align-items: center;
      justify-content: space-between;
      padding: 36px 28px 24px;

      z-index: 1;
      ${media.md`
        display: flex;
        overflow-x: hidden;
        margin-top: auto;
        flex-flow: row nowrap;
        justify-content: space-between;
        scroll-padding: 0;
      `}
      .hero__feature {
        display: flex;
        opacity: 0;
        transition: 1s;
        transform: scale(0);
        line-height: 1.12;
        align-items: center;
        img {
          max-width: 200px;
          object-fit: contain !important;
        }
        &:nth-child(2) {
          transition-delay: 100ms;
        }
        &:nth-child(3) {
          transition-delay: 200ms;
        }
        &:nth-child(4) {
          transition-delay: 300ms;
        }
        &:nth-child(5) {
          transition-delay: 400ms;
        }
        ${({ inView }) => inView && `
          opacity: 1;
          transform: scale(1);
        `}
        ${media.md`
          flex-flow: row nowrap;
          width: auto;
          flex-shrink: 1;
          h3, p {
            margin: 16px 0;
          }
        `}
        h3 {
          font-size: 32px;
          font-weight: 400;
          margin-bottom: 0;
        }
        p {
          font-size: 16px;
          margin-left: 1rem;
          margin-bottom: 0;
          text-align: left;
          white-space: nowrap;
        }
      }
      .gatsby-image-wrapper {
        width: 14rem !important;
      }
    }
  }
`;

export const BgImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left:0;
`;

export const MobileCarouselWrapper = styled.div`
  z-index: 1;
  padding: 64px 0;
  background: linear-gradient(180deg, rgba(0,0,0,0) 16.54%, #000 123.62%);
  .slick-dots li {
    margin: 0;
    padding: 0;
    width: 48px;
    button {
      padding: 0;
      &:before {
        background-color: white;
        border-radius: 0;
        content: "";
        height: 4px;
        width: 100%;
      }
    }
  }
  .slick-dots li.slick-active button:before {
    opacity: 1;
  }
  .hero__feature {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    h3 {
      font-size: 36px;
      margin: 0;
    }
    p {
      text-align: center;
      font-size: 18px;
      letter-spacing: 1px;
      line-height: 1.2;
    }
    .gatsby-image-wrapper {
      object-fit: scale-down;
      max-width: 280px !important;
    }
  }
`;
