import styled from 'styled-components';
import { HomepageStyles } from "../../sharedStyles";

export const StyledFeaturedClients = styled.section`
  ${HomepageStyles}
  padding-left: 0;
  padding-right: 0;
  .featured-clients-grid__list  {
    display: none;
  }
`;