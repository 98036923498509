import styled from 'styled-components';
import { Col, media } from "styled-bootstrap-grid"

export const StyledTestimonials = styled.section`
  ${({ htmlStyles }) => htmlStyles};
  margin-top: 140px;
  .section__right {
    display: none;
  }
  color: white;
  .container {
    height: 100%;
  }
  .section__left {
    display: flex;
    height: 100%;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    ${media.md`
        padding: 64px;
        text-align: left;
    `}
    h2 {
      font-size: 60px;
      font-weight: 400;
    }
    .btn-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      ${media.md`
        justify-content: flex-start;
      `}
      a {
        color: white;
        text-decoration: none;
        font-size: 18px;
        font-weight: 600;
        padding: 16px 32px;
        border: 1px solid white;
        transition: 0.33s;
        &:hover {
          background-color: white;
          border-color: #479FDB;
          color: #479FDB;
        }
      }
    }
  }
  > div {
    padding: 32px;
    ${media.md`
        padding: 64px 64px 64px 0;
    `}
  }
  .testimonials-slider__item__desc {
    font-size: 16px;
    line-height: 24px;
  }
  .testimonials-slider__item__author {
    font-size: 16px;
    font-weight: 600;
    line-height: 54px;
  }
  .slick-dots {
  text-align: center;
    ${media.md`
      text-align: right;
    `}
    font-size: 12px;
    li button:before {
      color: white;
      height: 20px;
      width: 20px;
    }
    li.slick-active button:before {
      opacity: 1;
    }
  }
  .slick-slider div {
    &:focus, &:active {
      outline-width: 0;
    }
  }
`;

export const LogoRow = styled.ul`
  display: flex;
  list-style-type: none;
  margin-left: 0;
  margin-bottom: 25px;
  width: 100%;
  align-items: center;
  & div.testimonials-slider{
    max-width:100%;
    & .slick-track{
      display:flex;
      align-items:center;
    }
    & .slick-slide{
      height:100%;
      &:not(.slick-current) div:first-child {
        max-width:100%;
        overflow:hidden;
      }
    }
    & img{
      padding-right:50px;
      min-width: 124px;
      max-width:100%;
      object-fit:contain;
    }
  }
  
`;

export const LogoWrap = styled.li`
  flex: 1;
  max-width: 100%;
  transition: 0.33s;
  opacity: ${({ active }) => active ? 1 : 0.5};
  &:not(:first-child) {
    padding-left: 32px;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const BorderLeftCol = styled(Col)`
  padding-left: 42px;
  margin-bottom: 50px;
  margin-top: 50px;
  ${media.md`
    border-left: 1px solid #FFFFFF;
  `}
`;