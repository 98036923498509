import styled from 'styled-components';
import { HomepageStyles } from "../../sharedStyles"
import { media } from "styled-bootstrap-grid"

export const StyledFeaturedPosts = styled.section`
  ${HomepageStyles}
  max-width: 100%;
  padding: 0 16px;
  > .container .section__title, > .container .section__description {
    display: none;
    &.show {
      display: inline-block;
    }
  }
  .featured-posts-grid__list {
    display: none;
  }
  .justify-content-center{
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .justify-content-start{
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .justify-content-end{
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
`;

export const BlogWrap = styled.div`
  display: flex;
  flex-flow: row wrap;
  > a {
    width: 100%;
    white-space: pre-wrap;
    position: relative;
    height: 0;
    padding-top: 100%;
    text-decoration: none;
    color: black;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    margin: 4px 0;
    ${media.md`
      width: calc(50% - 16px);
      padding-top: calc(50% - 16px);
      margin: 8px;
    `}
    ${media.lg`
      width: calc(25% - 16px);
      padding-top: calc(25% - 16px);
    `}
    strong {
      font-size: 16px;
    }
    > div, > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    > div {
      height: auto;
      top: auto;
      background-color: rgba(255, 255, 255, 0.95);
      bottom: 0;
      opacity: 0.9;
      padding: 32px 12px;
    }
    img {
      object-fit: contain;
      object-position: top center;
    }
  }
`;
